import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { CoreService } from '@app/core/services/core.service';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { ManipulatedProfileService } from '@app/modules/personalized-medications/services/manipulated-profile.service';
import { ModalSendDocumentComponent } from '@app/shared/components/modal-send-document/modal-send-document.component';
import { DateUtils } from '@app/shared/data/DateUtils';
import { Comercial, HealthProfessional, Patient } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { ProfessionalType } from '@app/shared/models/professional-type.enum';
import { OnlyNumbersPipe } from '@app/shared/pipes';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { ThemeService } from '@app/theme';
import { environment } from '@env/environment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, ReplaySubject, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { ModalSelectPetComponent } from '../patients/pages/modal-select-pet/modal-select-pet.component';
import { PetPatientService } from '../patients/services/pet-patient.service';
import { ModalAddPatientComponent } from './../patients/pages/modal-add-patient/modal-add-patient.component';
import { ModalSearchComponent } from './../patients/pages/modal-search/modal-search.component';
import { AttestationService } from './pages/attestation/attestation.service';
import { ModalIframeComponent } from './pages/modal-iframe/modal-iframe.component';
import { ModalListVictaProtocolComponent } from './pages/modal-list-victa-protocol/modal-list-victa-protocol.component';
import { ModalSelectLayoutComponent } from './pages/modal-select-layout/modal-select-layout.component';
import { ModelAddFavoriteDocumentComponent } from './pages/model-add-favorite-document/model-add-favorite-document.component';
import { ModelListFavoriteDocumentComponent } from './pages/model-list-favorite-document/model-list-favorite-document.component';
import { OrientationService } from './pages/orientation/orientation.service';
import { AttestationModelService } from './services/attestation-model.service';
import { DocumentService } from './services/document.service';
import { ExamModelService } from './services/exam-model.service';
import { ExamService } from './services/exam.service';
import { InfusionService } from './services/infusion.service';
import { OrientationModelService } from './services/orientation-model.service';
import { PrescriptionModelService } from './services/prescription-model.service';
import { VictaProtocolService } from './services/victa-protocol.service';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';
import { Prescription } from '@app/shared/models/prescription';
import { CrService } from './services/cr.service';
import { Base64Utils } from '@app/shared/utils/base64.utils';

type ReponsePlusDocuments = {
  id: number;
  downloadLink: string;
};

type ReponsePlus = {
  message: string;
  documents: ReponsePlusDocuments[];
};

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, OnDestroy, AfterViewInit {
  actualDate = new Date();
  nzBackfill = false;
  isPatientNotFound = false;
  private patientCpf = '';
  local: Comercial;
  search = true;
  loading = false;
  signed = false;

  prescriber: HealthProfessional;
  patient: Patient;
  patients: Patient[];

  documentForm: FormGroup;
  patientForm: FormGroup;
  document: any;
  manipulatedProfile: any;
  selectedDocumentModel: any;
  modelId: any;

  adding = false;
  typesOfDocuments = ['Prescrição', 'Exame', 'Atestado', 'Outros'];
  typesOfDocumentsOptions = this.typesOfDocuments;
  documentTypes: any = {
    prescription: 'Prescrição',
    exam: 'Exame',
    attestation: 'Atestado',
    orientation: 'Outros'
  };
  documentType: string;
  backendVersion$: Observable<string>;
  isMobile = false;

  @ViewChild('addPatient')
  addPatient: any;

  @ViewChild(ModalSendDocumentComponent, { static: true })
  modalSendDocumentComponent: ModalSendDocumentComponent;

  @ViewChild(ModalSelectLayoutComponent, { static: true })
  modalSelectLayout: ModalSelectLayoutComponent;

  @ViewChild(ModalIframeComponent, { static: true })
  modalIframe: ModalIframeComponent;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = this.coreService.isViewPortMobile();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private healthProfessionalService: HealthProfessionalService,
    private patientService: PatientsService,
    private petService: PetPatientService,
    private prescriptionService: PrescriptionService,
    private attestationService: AttestationService,
    private examService: ExamService,
    private orientationService: OrientationService,
    private infusionService: InfusionService,
    private prescriptionModelService: PrescriptionModelService,
    private attestationModelService: AttestationModelService,
    private examModelService: ExamModelService,
    private orientationModelService: OrientationModelService,
    private victaProtocolService: VictaProtocolService,
    private notification: AppToastService,
    private authService: AuthService,
    private documentService: DocumentService,
    private manipulatedProfileService: ManipulatedProfileService,
    private modalService: BsModalService,
    private nzModalService: NzModalService,
    private themeService: ThemeService,
    private coreService: CoreService,
    private cdr: ChangeDetectorRef,
    private crService: CrService
  ) {
    this.documentType = this.typesOfDocuments[0];

    this.documentForm = this.fb.group({});
    this.patientForm = this.fb.group({
      _id: [null],
      cpf: [null],
      name: ['', [Validators.required]]
    });
    this.backendVersion$ = this.coreService.getBackendVersion();

    if (this.victaFeature) {
      this.typesOfDocuments.push('Infusão');
      this.documentTypes.infusion = 'Infusão';
      this.documentType = 'Infusão';
    }
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  @nativeAsync
  async ngOnInit() {
    this.loading = true;
    await this.requests(this.user._id);

    const {
      code,
      response,
      documentType,
      profileId,
      patientId,
      petId
    } = this.route.snapshot.queryParams;
    if (code && response && (patientId || petId)) {
      const responsePlus = Base64Utils.decode<ReponsePlus>(response);
      if (responsePlus.message === 'Signed OK' || responsePlus.message === 'Signed local OK') {
        this.modalSendDocumentComponent.createTplModal(code, patientId || petId, { isPet: !!petId });
      } else {
        this.notification.notify('error', 'Erro ao assinar documento!');
        console.log(response);
        this.router.navigate(['/prescription', code, 'edit']);
      }
    }

    if (profileId) {
      await this.getManipulatedProfile(profileId);
    }

    const id = this.route.snapshot.params.id || (!response && code);
    if (id) {
      try {
        const document = await this.documentService.getDocument(id, documentType);
        if (document.document === 'prescription') {
          await this.updateMedicaments(document);
        }
        this.fillForm(document);
      } catch (err) {
        console.error(err);
        this.router.navigate(['/prescription/new']);
      }
    }

    this.onChange();
    this.modelId = this.route.snapshot.params.modelId;
    const type = this.route.snapshot.params.documentType;

    if (this.modelId) {
      this.adding = true;
      this.documentType = this.documentTypes[type];
      this.selectedDocumentModel = await this.getDocumentModel();
    } else if (this.documentService.patientForm) {
      this.adding = true;
      this.patientForm = this.documentService.patientForm;
      this.documentService.patientForm = null;
    }

    this.loading = false;
  }

  get user(): User {
    return this.authService.user();
  }

  get patientRecordFeature(): boolean {
    return this.user && !!this.user.features.find(f => f.name === 'Prontuário');
  }

  get victaFeature(): boolean {
    return this.user && !!this.user.features.find(f => f.name === 'Victa');
  }

  get frontendVersion(): string {
    return this.coreService.frontendVersion;
  }

  get isVeterinarian(): boolean {
    return this.prescriber && this.prescriber.professionalType === ProfessionalType.VETERINARIAN;
  }

  get showCpfText() {
    return this.patient && !this.patient.cpf ? 'Mostrar CPF do Responsável' : 'Mostrar CPF do Paciente';
  }

  @nativeAsync
  private async getManipulatedProfile(profileId) {
    const manipulatedProfile = await this.manipulatedProfileService
      .getManipulatedProfileById(profileId)
      .toPromise();
    this.patientForm.patchValue({ ...manipulatedProfile.patient });
    this.manipulatedProfile = manipulatedProfile;
    this.typesOfDocumentsOptions = [this.typesOfDocuments[0]];
    this.adding = true;
    this.patientForm.disable();
  }
  @nativeAsync
  private async getDocumentModel() {
    let documentsModel: any;

    switch (this.documentType) {
      case this.typesOfDocuments[0]:
        documentsModel = await this.prescriptionModelService.getById(this.modelId);
        break;
      case this.typesOfDocuments[1]:
        documentsModel = await this.examModelService.getById(this.modelId);
        break;
      case this.typesOfDocuments[2]:
        documentsModel = await this.attestationModelService.getById(this.modelId);
        break;
      case this.typesOfDocuments[3]:
        documentsModel = await this.orientationModelService.getById(this.modelId);
        break;
    }
    return documentsModel;
  }

  get examList(): void {
    if (this.selectedDocumentModel) {
      return this.selectedDocumentModel.examList;
    }
    return this.document && this.document.examList;
  }

  private fillForm(document: any) {
    this.documentType = document.type;
    this.local = document.local;

    this.patientForm.patchValue(document.patient || document.pet);
    this.patient = document.patient || document.pet;

    this.document = document;
    if (this.route.snapshot.routeConfig.path === ':id/edit') {
      this.signed = document.status === 'Assinada';
      if (document.manipulatedprofile) {
        this.typesOfDocumentsOptions = [this.typesOfDocuments[0]];
        this.patientForm.disable();
      }
    } else {
      delete document.code;
      delete document.status;
    }

    this.toggleAdding();
  }
  @nativeAsync
  private async requests(userId: string) {
    const patient = JSON.parse(localStorage.getItem('patient'));
    if (patient) {
      this.patientForm.patchValue(patient);
      this.patient = patient;
      localStorage.removeItem('patient');
    }
    await this.getPrescriberData(userId);
  }
  @nativeAsync
  private async getPrescriberData(userId: string) {
    this.prescriber = await this.healthProfessionalService.getHealthProfessional(userId).toPromise();
    this.local = this.prescriber && this.prescriber.commercialData[0];
  }

  showModalSearch(cpf?: string) {
    const initialState = {
      user: this.user,
      prescriber: this.prescriber,
      addPatient: true,
      cpf
    };
    const modal = this.modalService.show(ModalSearchComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false
    });

    modal.content.updateListPatientEvent.subscribe(patient => {
      this.patientForm.patchValue(patient);
      this.onChangePatient(patient);
      this.patient = patient;
    });
    modal.content.openModalAddPatientEvent.subscribe(consult => this.showModalAddPatient(consult));
    modal.content.openModalSelectPetEvent.subscribe(consult => this.showModalSelectPet(consult));
    modal.content.loadingSpinnerEvent.subscribe(loading => (this.loading = loading));
  }

  private showModalAddPatient(consult) {
    const initialState = {
      prescriber: this.prescriber,
      patient: consult.patient,
      isResponsible: consult.isResponsible,
      existingPatient: consult.existingPatient
    };
    const modal = this.modalService.show(ModalAddPatientComponent, {
      class: 'modal-lg',
      initialState,
      backdrop: 'static',
      keyboard: false
    });
    modal.content.updateListPatientEvent.subscribe(patient => {
      this.patientForm.patchValue(patient);
      this.onChangePatient(patient);
      this.patient = patient;
    });
  }

  private showModalSelectPet(consult) {
    const initialState = {
      prescriber: this.prescriber,
      pets: consult.patient.pets,
      responsible: consult.patient
    };
    const modal = this.modalService.show(ModalSelectPetComponent, {
      initialState,
      backdrop: 'static',
      keyboard: false
    });

    modal.content.submit.subscribe(patient => {
      this.patientForm.patchValue(patient);
      this.onChangePatient(patient);
      this.patient = patient;
    });
  }

  toggleAdding() {
    if (this.patientForm.get('_id').value === null) {
      this.notification.notify('warning', 'Aviso', 'Você precisa selecionar um paciente para iniciar');
    } else {
      this.adding = !this.adding;
    }
  }

  onChangePatient(patient: Patient): void {
    if (patient._id === undefined && this.onlyNumbers(this.patientCpf)) {
      this.showModalSearch(this.patientCpf);
    } else {
      this.patient = patient;
      this.patientForm.get('_id').setValue(patient._id);
      this.adding = true;
    }
  }

  handleCancel() {
    this.patients = [];
    this.patientForm
      .get('name')
      .setValue(this.patientForm.get('name').value.replace(/(Adicionar paciente )/gim, ''));
  }

  onChange(): void {
    let inputValue = '';

    this.patientForm
      .get('name')
      .valueChanges.pipe(
        debounceTime(environment.debounceTime),
        distinctUntilChanged(),
        tap(value => (inputValue = value)),
        switchMap(value => this.getPatients(value)),
        map((res: any) => {
          if (res) {
            if (this.isVeterinarian) {
              const { pets, patients } = res;
              if (pets) {
                // get pet by cpf
                if (pets._id) {
                  return [pets];
                }

                // get pets by name
                if (pets.length > 0) {
                  return pets;
                }
              }

              // no pets but patients (responsible) may have been found
              return this.handleResponse(inputValue, !!patients);
            }

            // get patient by cpf
            if (res._id) {
              return [res];
            }

            // get patients by name
            if (res.length > 0) {
              return res;
            }

            // no patients
            return this.handleResponse(inputValue);
          } else {
            this.patientForm.get('_id').setValue(null);
          }
        }),
        takeUntil(this.destroyed$),
        catchError((err, source) => {
          let arr = [];
          if (err.status === 404) {
            arr = this.handleResponse(inputValue);
          }
          return source.pipe(startWith(arr));
        })
      )
      .subscribe(patients => (this.patients = patients));
  }

  private onlyNumbers(value: String): Boolean {
    return value.match(/^[0-9]+$/) != null;
  }

  private getPatients(value: string) {
    if (!value || value.length < 3) {
      return of(null);
    }

    if (!value || value.length < 3) {
      return of(null);
    }

    const valueWithoutCharacters = new OnlyNumbersPipe().transform(value);
    const onlyNumbers = this.onlyNumbers(valueWithoutCharacters);

    const fullCpf = onlyNumbers && valueWithoutCharacters.length === 11;

    if (this.isVeterinarian) {
      if (fullCpf) {
        // get pet by cpf
        return this.petService.getPetsByResponsibleCpf(valueWithoutCharacters).pipe(
          map(res => ({ pets: res })),
          switchMap(res => {
            if (res.pets.length) {
              return of(res);
            }
            // if there are no pets, then try to get some patient by cpf
            return this.patientService
              .getPatientByCpf(valueWithoutCharacters)
              .pipe(map(res1 => ({ patients: res1 })));
          })
        );
      }

      // get pets by name
      return this.healthProfessionalService.getPets(value).pipe(map(res => ({ pets: res })));
    }

    if (fullCpf) {
      return this.patientService.getPatientByCpf(valueWithoutCharacters);
    }

    return this.healthProfessionalService.getPatientsByUserId(valueWithoutCharacters, this.user._id);
  }

  private handleResponse(patientInfo: string, responsibleExists = false) {
    const cpf = new OnlyNumbersPipe().transform(patientInfo);
    const isOnlyNumbers = this.onlyNumbers(cpf);

    if (!isOnlyNumbers) {
      if (this.isVeterinarian) {
        return [
          {
            cpf: '-1',
            fullname:
              'Você não possui esse paciente em sua lista de pacientes. ' +
              'Digite o CPF completo para prescrever uma receita e o adicionaremos automaticamente à sua lista.'
          }
        ];
      }
      return [
        {
          cpf: '-1',
          fullname:
            'Você não possui esse paciente em sua lista de pacientes. ' +
            'Digite o CPF completo para prescrever uma receita e o adicionaremos automaticamente à sua lista.'
        }
      ];
    }

    if (isOnlyNumbers && cpf.length === 11) {
      this.patientCpf = cpf;
      let fullname = `Paciente com CPF ${patientInfo} não localizado na base. Deseja adicionar paciente?`;
      if (this.isVeterinarian) {
        if (responsibleExists) {
          fullname = `Responsável/Tutor com CPF ${patientInfo} já é cadastrado na base. Deseja adicionar?`;
        } else {
          fullname = `Responsável/Tutor com CPF ${patientInfo} não cadastrado na base. Deseja adicionar?`;
        }
      }

      return [{ cpf: '-1', fullname }];
    }
  }

  setSearch(search: boolean) {
    this.search = search;
  }
  @nativeAsync
  private async saveDocument() {
    try {
      const doc = this.documentForm.getRawValue();
      const patient = this.patientForm.value;
      if (this.isVeterinarian) {
        doc.pet = patient;
      } else {
        doc.patient = patient;
      }
      doc.local = this.local;
      doc.code = this.document && this.document.code;
      let documentSaved: any;
      switch (this.documentType) {
        case 'Prescrição':
          documentSaved = await this.prescriptionService
            .save({ prescriber: this.user._id, ...doc })
            .pipe(take(1))
            .toPromise();
          documentSaved.type = 'prescription';
          break;
        case 'Atestado':
          documentSaved = await this.attestationService
            .save({ prescriber: this.user._id, ...doc })
            .toPromise();
          documentSaved.type = 'attestation';
          break;
        case 'Exame':
          const message = this.hasDuplicatedMaterials(this.documentForm.value.examList);
          if (message) {
            this.notification.notify('warning', 'Aviso', message);
            this.loading = false;
            return null;
          }

          if (!this.documentForm.valid) {
            this.notification.notify(
              'warning',
              'Aviso',
              'Você adicionou um novo campo mas não o preencheu.'
            );
            this.loading = false;
            return null;
          }

          documentSaved = await this.examService.save({ prescriber: this.user._id, ...doc }).toPromise();
          documentSaved.type = 'exam';

          break;
        case 'Outros':
          if (!this.documentForm.valid) {
            this.notification.notify('warning', 'Aviso', 'Orientações não pode ficar em branco');
            this.loading = false;
            return null;
          }
          documentSaved = await this.orientationService
            .save({ prescriber: this.user._id, ...doc })
            .toPromise();
          documentSaved.type = 'orientation';
          break;
        case 'Infusão':
          documentSaved = await this.infusionService
            .save({ prescriber: this.prescriber.userId, ...doc })
            .pipe(take(1))
            .toPromise();
          documentSaved.type = 'infusion';
          break;
        default:
          console.warn('Document type unknown: ', this.documentType);
          this.loading = false;
          return null;
      }
      return documentSaved;
    } catch (err) {
      if (err.status === 400) {
        this.notification.notify(
          'error',
          'Erro ao salvar documento',
          err.error.error && err.error.error.message
        );
      } else {
        this.notification.notify(
          'error',
          'Erro',
          `Erro ao salvar um documento do tipo ${this.documentType}`
        );
      }
      throw err;
    }
  }

  private hasDuplicatedMaterials(examList: any[]) {
    const duplicatedMaterials = this.findDuplicatedMaterials(examList);

    const duplicatedExams = examList
      .map(element => this.findDuplicatedExams(element.exams))
      .filter(arr => arr.length);

    if (duplicatedMaterials.length > 0) {
      return `Prescrição não pode conter materiais repetidos: ${duplicatedMaterials.join(', ')}.`;
    }
    if (duplicatedExams.length > 0) {
      return `Prescrição não pode conter exames repetidos: ${duplicatedExams.join(', ')}.`;
    }
    return '';
  }

  private findDuplicatedMaterials(arr) {
    return this.findDuplicatedItems(arr, 'material');
  }

  private findDuplicatedExams(arr) {
    return this.findDuplicatedItems(arr, 'name');
  }

  private findDuplicatedItems(arr, field) {
    const sorted_arr = arr.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    const results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1][field] === sorted_arr[i][field]) {
        results.push(sorted_arr[i][field]);
      }
    }
    return results;
  }
  @nativeAsync
  async saveAndPreviewPdf() {
    if (this.documentType === this.documentTypes.prescription) {
      this.selectPrescriptionLayout('preview');
    } else {
      try {
        this.loading = true;
        const documentSaved = await this.saveDocument();
        await this.previewPdf(documentSaved);
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    }
  }
  @nativeAsync
  private async previewPdf(documentSaved: any, layout = {}) {
    try {
      this.loading = true;
      layout['theme'] = this.themeService.getActiveTheme().name;
      await this.documentService.generateAndOpenPdf(documentSaved.code, layout);
      this.router.navigate(['/prescription', documentSaved.code, 'edit']);
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }
  @nativeAsync
  async saveAndSignPdf() {
    if (this.documentType === this.documentTypes.prescription) {
      this.selectPrescriptionLayout('sign');
    } else {
      try {
        this.loading = true;
        const documentSaved = await this.saveDocument();
        await this.signPdf(documentSaved.code);
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    }
  }
  @nativeAsync
  private async signPdf(code: string, layout = {}) {
    layout['theme'] = this.themeService.getActiveTheme().name;
    const url = await this.documentService.getUrlToSignDocument({
      code,
      prescriber: this.prescriber,
      patientId: this.patientForm.value._id,
      layout,
      victaFeature: this.victaFeature
    });
    window.location.href = url;
    // this.showSignatureModal(url);
  }

  private selectPrescriptionLayout(method: string) {
    let patientDoesNotHaveAddress = false;

    // TODO: verificar a questão de paciente menor de idade quando fica maior de idade o que ocorre com o responsável
    if (this.patient && this.patient.responsible) {
      patientDoesNotHaveAddress = this.responsibleDoesNotHaveAddress();
    } else {
      patientDoesNotHaveAddress = this.patientDoesNotHaveAddress();
    }

    this.modalSelectLayout.createTplModal(patientDoesNotHaveAddress);

    this.modalSelectLayout.selectLayout.pipe(take(1)).subscribe(async data => {
      this.loading = true;
      try {
        const { layout, addressValue } = data;
        if (layout === 'special' && patientDoesNotHaveAddress) {
          const updatedPatient = await this.updatePatientAddress(addressValue);
          if (this.patient.responsible) {
            this.patient.responsible = updatedPatient;
          } else {
            this.patient = updatedPatient;
          }
        }
        delete data.addressValue;
        await this.savedDocumentAndPreviewPdfOrSignPdf(method, data);
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    });
  }

  get isUnderAge() {
    if (this.patient.dateOfBirth) {
      return DateUtils.isUnderAge(this.patient.dateOfBirth);
    }
    return false;
  }

  private patientDoesNotHaveAddress() {
    return (
      !this.patient.address ||
      (this.patient.address &&
        !this.patient.address.cep &&
        !this.patient.address.street &&
        !this.patient.address.number &&
        !this.patient.address.neighborhood &&
        !this.patient.address.city &&
        !this.patient.address.uf)
    );
  }

  private responsibleDoesNotHaveAddress() {
    return (
      !this.patient.responsible ||
      !this.patient.responsible.address ||
      (this.patient.responsible.address &&
        !this.patient.responsible.address.cep &&
        !this.patient.responsible.address.street &&
        !this.patient.responsible.address.number &&
        !this.patient.responsible.address.neighborhood &&
        !this.patient.responsible.address.city &&
        !this.patient.responsible.address.uf)
    );
  }

  updatePatientAddress(address: any): Promise<Patient> {
    return new Promise(async (resolve, reject) => {
      try {
        const isUpdateAddressPatient = !this.patient.responsible;
        const restData = isUpdateAddressPatient ? this.patient : this.patient.responsible;
        const dataPatient = { ...restData, address };
        const patient = await this.patientService.update(restData._id, dataPatient).toPromise();
        resolve(patient);
      } catch (error) {
        this.notification.notify(
          'error',
          'Error',
          'Ocorreu um erro ao tentar atualizar endereço do paciente!'
        );
        this.loading = false;
        reject(error);
      }
    });
  }

  @nativeAsync
  async savedDocumentAndPreviewPdfOrSignPdf(method: string, data: {}) {
    const documentSaved = await this.saveDocument();
    if (method === 'preview') {
      await this.previewPdf(documentSaved, data);
    } else {
      await this.signPdf(documentSaved.code, data);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  changeLocal(address) {
    this.local = address;
  }

  changeDocumentType(documentType) {
    this.documentType = documentType;
  }

  get invalidForm(): boolean {
    const obj = this.documentForm.value;
    return (
      !this.patientForm.value._id ||
      !this.patientForm.value.name ||
      !this.documentForm.valid ||
      (Object.keys(obj).length === 0 && obj.constructor === Object) ||
      this.signed ||
      !!this.modelId
    );
  }

  private resetForm() {
    this.patientForm.reset();
    this.documentForm.reset();
    this.selectedDocumentModel = null;
    this.adding = false;
  }

  @nativeAsync
  async showVictaProtocols() {
    try {
      this.loading = true;
      const groups = await this.victaProtocolService.getProtocols(this.prescriber.professionalType);
      this.loading = false;

      const modal = this.modalService.show(ModalListVictaProtocolComponent, {
        initialState: { groups },
        backdrop: 'static'
      });

      modal.content.selectEvent.subscribe(protocol => {
        this.selectedDocumentModel = protocol;
        this.adding = true;
      });
    } catch (err) {
      this.loading = false;
      this.notification.notify('warning', 'Aviso', 'Não foi possível recuperar protocolos');
    }
  }

  @nativeAsync
  async showModelListFavoriteDocument() {
    try {
      this.loading = true;
      const documentsTemplate = await this.getListDocumentsTemplates();
      this.loading = false;

      if (documentsTemplate.length) {
        const initialState = {
          documentType: this.documentType,
          documentsTemplate
        };
        this.showFavoritePrescriptionsModel(initialState);
      } else {
        this.notification.notify(
          'warning',
          'Aviso',
          `Você ainda não possui modelos de ${this.documentType} cadastrados. Salve um modelo em Favoritos.`
        );
      }
    } catch (err) {
      this.loading = false;
      this.notification.notify('warning', 'Aviso', 'Não foi possível recuperar a lista de modelos');
    }
  }

  private showFavoritePrescriptionsModel(initialState: any) {
    const modal = this.modalService.show(ModelListFavoriteDocumentComponent, {
      initialState,
      backdrop: 'static'
    });
    modal.content.selectEvent.subscribe(model => {
      this.selectedDocumentModel = model;
      this.adding = true;
    });

    modal.content.editEvent.subscribe(model => {
      this.documentService.patientForm = this.patientForm;
      model.document = model.document || 'prescription';
      this.router.navigate(['/prescription/model', model._id, model.document, 'edit']);
    });

    modal.content.deleteEvent.subscribe((modelId: string) => {
      modal.hide();
      this.nzModalService.confirm({
        nzTitle: 'Atenção',
        nzContent: `Tem certeza que deseja excluir este modelo de ${this.documentType} da sua lista?`,
        nzOnOk: async () => {
          try {
            await this.deleteDocumentModel(modelId);
            this.notification.notify('success', 'Sucesso', 'Modelo excluído com sucesso');
          } catch (err) {
            this.notification.notify('warning', 'Aviso', 'Não foi possível excluir este item');
          }
        },
        nzOnCancel: () => this.showFavoritePrescriptionsModel(initialState)
      });
    });
  }

  @nativeAsync
  private async deleteDocumentModel(modelId) {
    switch (this.documentType) {
      case this.typesOfDocuments[0]:
        await this.prescriptionModelService.deleteById(modelId);
        break;
      case this.typesOfDocuments[1]:
        await this.examModelService.deleteById(modelId);
        break;
      case this.typesOfDocuments[2]:
        await this.attestationModelService.deleteById(modelId);
        break;
      case this.typesOfDocuments[3]:
        await this.orientationModelService.deleteById(modelId);
        break;
    }
  }

  @nativeAsync
  private async getListDocumentsTemplates() {
    let documentsTemplate = [];
    switch (this.documentType) {
      case this.typesOfDocuments[0]:
        documentsTemplate = await this.prescriptionModelService.getListByPrescriberId(
          this.prescriber.userId
        );
        break;
      case this.typesOfDocuments[1]:
        documentsTemplate = await this.examModelService.getListByPrescriberId(this.prescriber.userId);
        break;
      case this.typesOfDocuments[2]:
        documentsTemplate = await this.attestationModelService.getListByPrescriberId(
          this.prescriber.userId
        );
        break;
      case this.typesOfDocuments[3]:
        documentsTemplate = await this.orientationModelService.getListByPrescriberId(
          this.prescriber.userId
        );
        break;
    }

    return documentsTemplate.filter(documentTemplate => documentTemplate.version !== 'BETA');
  }

  showModalAddFavoriteDocument() {
    if (this.documentForm.valid) {
      this.documentForm.value.prescriber = this.prescriber.userId;
      const initialState = {
        documentModel: this.documentForm.value,
        documentType: this.documentType
      };
      this.modalService.show(ModelAddFavoriteDocumentComponent, {
        class: 'modal-lg',
        initialState,
        backdrop: 'static',
        keyboard: false
      });
    } else {
      const msg = this.getFormError(this.documentType);
      this.notification.notify('warning', 'Aviso', msg);
    }
  }

  @nativeAsync
  async updateDocumentModel(model: any, modelId: string) {
    try {
      let modelSaved = null;

      switch (this.documentType) {
        case this.typesOfDocuments[3]:
          modelSaved = await this.orientationModelService.update(model, modelId);
          break;
        case this.typesOfDocuments[2]:
          modelSaved = await this.attestationModelService.update(model, modelId);
          break;
        case this.typesOfDocuments[1]:
          modelSaved = await this.examModelService.update(model, modelId);
          break;

        default:
          modelSaved = await this.prescriptionModelService.update(model, modelId);
      }

      this.selectedDocumentModel = undefined;
      this.modelId = undefined;

      this.notification.notify('success', 'Sucesso', 'Modelo salvo com sucesso');
      this.router.navigate(['/prescription/new']);
    } catch (err) {
      console.error(err);
      this.notification.notify('error', 'Aviso', 'Erro ao atualizar modelo');
    }
  }

  cancelEditModel() {
    this.router.navigate(['/prescription/new']);
  }

  isThemeActiveReceitaDigital() {
    const active = this.themeService.getActiveTheme();
    return active.name === 'theme-receita-digital';
  }

  async updateMedicaments(prescription: Prescription) {
    for await (const medicament of prescription.medicaments) {
      if (!!medicament.ean) {
        try {
          const medicamentsCR = await this.crService.searchMedicament(medicament.ean).toPromise();
          if (medicamentsCR.length > 0) {
            const medicamentCR = medicamentsCR[0];
            medicament.name = medicamentCR.nome;
            medicament.prescriptionTypeId = medicamentCR.prescriptionTypeId;
            medicament.typeCr = medicamentCR.typeCr;
            medicament.type = 'industrialized';
            medicament.laboratory = medicamentCR.laboratorio;
            medicament.description = medicamentCR.apresentacaoCustomizada;
            medicament.medicamentId = medicamentCR.id;
            medicament.principle = medicamentCR.principio;
          }
        } catch (error) {
          console.log(error);
          this.notification.notify('error', 'Erro ao consultar dados do medicamento.');
        }
      } else {
        medicament.type = 'free-text';
      }
    }
  }

  private getFormError(documentType: string): string {
    switch (documentType) {
      case 'Prescrição':
        return 'Selecione e preencha dados do medicamento ou digite um texto livre';
      case 'Exame':
        return 'Selecione um material e um exame para cada material';
      default:
        return 'Preencha os campos obrigatórios';
    }
  }

  clearSelectionPatient() {
    this.patient = null;
    this.patientForm.reset();
  }
}
